.datePicker1 {
  min-width: 11rem;
  max-width: max-content;

  height: 40px;
  border-radius: 0;
  outline: none;

  position: relative;
  display: flex;
  border-radius: 5px;

  .react-datepicker-wrapper {
    height: 100%;
    width: 100%;

    & input {
      outline: none;
      padding-left: 10px !important;
    }

    .datepicker1_custom_input {
      display: flex;
      flex-direction: row-reverse;
      justify-content: start;
      gap: 0.5rem;
      height: 100%;
      width: 100%;
      align-items: center;
      padding-left: 0.6rem;
      position: relative;

      .datepicker1_custom_input_label_top {
        position: absolute;
        bottom: 65%;
        font-size: 10px;
      }
      .datepicker1_custom_input_label {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        font-size: 0.8rem;
        opacity: 0.7;
        color: #000000;
      }
    }
    .placeholder_styling {
      font-size: 1rem !important;
      font-weight: 500 !important;
    }

    & > * {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 5px;
    }
    .react-datepicker__close-icon {
      width: max-content;
      height: max-content;
      top: -34%;
      &::after {
        cursor: pointer;
        background-color: transparent;
        color: hsl(0, 0%, 0%);
        border-radius: 50%;

        padding: 2px;
        font-size: 14px;
        line-height: 3;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        content: "✕";
      }
    }
  }

  .react-datepicker-popper {
    z-index: 2;
  }
  .selected_fill {
    background-color: rgb(228 223 240) !important;

    border-radius: 0px;
  }
}
.react-datepicker__day {
  &--selected,
  &--keyboard-selected {
    background-color: rgb(33, 21, 81);
    color: white;
  }
}
